@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.orange-gradient {
  @apply bg-gradient-to-b from-primary from-30% to-secondary;
}

.gradienthome {
  background-size: 100% 100%;
  background-position: 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px;
  background-image: radial-gradient(49% 81% at 45% 47%, #1976d212 0%, #073aff00 100%),
    radial-gradient(113% 91% at 17% -2%, #171b21ff 1%, #ff000000 99%),
    radial-gradient(142% 91% at 83% 7%, #101215ff 1%, #ff000000 99%),
    radial-gradient(142% 91% at -6% 74%, #171b21ff 1%, #ff000000 99%),
    radial-gradient(142% 91% at 111% 84%, #171b21ff 0%, #1976d2ff 100%);
}

.scrolldown {
  --color: #ffffff;
  --sizeX: 30px;
  --sizeY: 50px;
  position: relative;
  width: var(--sizeX);
  height: var(--sizeY);
  margin-left: var(sizeX / 2);
  border: calc(var(--sizeX) / 10) solid var(--color);
  border-radius: 50px;
  box-sizing: border-box;
  margin-bottom: 16px;
  cursor: pointer;
}

.scrolldown::before {
  content: '';
  position: absolute;
  bottom: 30px;
  left: 50%;
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: var(--color);
  border-radius: 100%;
  animation: scrolldown-anim 2s infinite;
  box-sizing: border-box;
  box-shadow: 0px -5px 3px 1px #2a547066;
}

@keyframes scrolldown-anim {
  0% {
    opacity: 0;
    height: 6px;
  }

  40% {
    opacity: 1;
    height: 10px;
  }

  80% {
    transform: translate(0, 20px);
    height: 10px;
    opacity: 0;
  }

  100% {
    height: 3px;
    opacity: 0;
  }
}

.chevrons {
  padding: 6px 0 0 0;
  margin-left: -3px;
  margin-top: 48px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chevrondown {
  margin-top: -6px;
  position: relative;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}

.chevrondown:nth-child(odd) {
  animation: pulse54012 500ms ease infinite alternate;
}

.chevrondown:nth-child(even) {
  animation: pulse54012 500ms ease infinite alternate 250ms;
}

@keyframes pulse54012 {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.5;
  }
}

.green-card {
  @apply from-green-400 to-green-600 hover:to-green-700;
}

.yellow-card {
  @apply from-yellow-400 to-yellow-600 hover:to-yellow-700;
}

.red-card {
  @apply from-red-500 to-red-700 hover:to-red-800;
}

.blue-card {
  @apply from-blue-400 to-blue-600 hover:to-blue-700;
}

.purple-card {
  @apply from-purple-400 to-purple-600 hover:to-purple-700;
}

.orange-card {
  @apply from-orange-400 to-orange-600 hover:to-orange-700;
}
